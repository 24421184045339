$(document).ready(function () {
	$('.c-faqs__item-question').click(function () {
		$('.c-faqs__item-answer').slideUp('fast');
		$('.c-faqs__item').removeClass('--is-active');
		$(this).closest('.c-faqs__item').addClass('--is-active');
		let answer = $(this).siblings('.c-faqs__item-answer');
		if (answer.is(':hidden')) {
			answer.slideDown('fast');
		} else {
			answer.slideUp('fast');
		}
	});
});
