$(document).ready(function () {
	// let fired = false;
	// let shown = false;

	// const responsive = () => {
	// 	if (window.innerWidth < 1540 && fired == false) {
	// 		document.body.style.zoom = '80%';
	// 		shown = true;
	// 		Swal.fire({
	// 			text: 'Hello Monica, for best viewing experience we recommend zooming out the browser, as your display is set to 125% zoom level. Please zoom out two clicks to 80% for best viewing experience',
	// 			confirmButtonText: 'Close',
	// 			allowOutsideClick: false,
	// 		});
	// 	} else if (window.innerWidth >= 1920 && fired == false && shown == true) {
	// 		fired = true;
	// 		Swal.fire({
	// 			text: 'Perfect!',
	// 			confirmButtonText: 'Close',
	// 			allowOutsideClick: false,
	// 		});

	// 		setTimeout(function () {
	// 			swal.close();
	// 		}, 2000);
	// 	}
	// };

	// $(document).ready(responsive);
	// $(window).resize(responsive);

	// let fired = false;
	// let shown = false;

	const responsive = () => {
		// if (window.innerWidth < 1540) {
		// 	document.body.style.zoom = '80%';
		// }
	};

	$(document).ready(responsive);
	$(window).resize(responsive);
});
