$(document).ready(function () {
	// const form = $('[data-signup-form]');
	// const input = $('[data-signup-form-email]');
	// const submit = $('[data-signup-form-submit]');
	// form[0].noValidate = true;
	// let email = '';
	// input.on('input', function (e) {
	// 	e.preventDefault();
	// 	email = $(this).val();
	// 	$('.gravity-form form #input_1_1').val(email).trigger('change');
	// });
	// submit.on('click', function (e) {
	// 	e.preventDefault();
	// 	$('.gravity-form form').trigger('submit');
	// 	setTimeout(function () {
	// 		if ($('.gravity-form form').find('#validation_message_1_1').length != 0) {
	// 			form.append($('.gravity-form form').find('#validation_message_1_1'));
	// 		} else {
	// 			form.find('#validation_message_1_1').hide();
	// 		}
	// 	}, 1000);
	// });

	$('[data-pricing-signup]').on('click', function () {
		$('#signup-modal').modal();
	});
});
