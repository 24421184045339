const MobileMenu = {
	init: function () {
		this.toggleMenu();
	},

	toggleMenu: function () {
		const menu = $('[data-primary-menu]');
		const button = $('[data-primary-menu-mobile-button');

		const displayMenu = () => {
			$('body').toggleClass('--mobile-menu-active');
			menu.find('.c-button')
				.removeClass('c-button c-button--primary')
				.addClass('c-primary-menu__link');
		};

		const responsive = () => {
			if (window.innerWidth < 991.98) {
				button.show();
			} else {
				$('body').removeClass('--mobile-menu-active');
				menu.find('.c-primary-menu__button')
					.addClass('c-button c-button--primary')
					.removeClass('c-primary-menu__link');
				button.hide();
			}
		};

		button.on('click', function () {
			displayMenu();
		});

		$(document).ready(responsive);
		$(window).resize(responsive);
	},
};

$(window).ready(function () {
	MobileMenu.init();
});
